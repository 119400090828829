$peakblue : #00a2b2;

@import url("https://use.typekit.net/ctf3oer.css");


.font-futura-pt-book {
    font-family: "futura-pt", sans-serif;
    font-weight: 400;
    font-style: normal;
}

html {
    overflow-x: hidden;
}

.font-futura-pt-demi {
    font-family: "futura-pt", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.font-futura-pt {
    font-family: "futura-pt", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.font-futura-pt-bold {
    font-family: "futura-pt-bold", sans-serif;
    font-weight: 700;
    font-style: normal;
}



.font-futura-pt-heavy {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    font-style: normal;
}



.font-futura-pt-extra {
    font-family: "futura-pt", sans-serif;
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Web';
    src: url('../fonts/Myriad-BdWeb.woff2') format('woff2'),
        url('../fonts/Myriad-BdWeb.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.font-myriad-web-bold {
    font-family: 'Myriad Web';
    font-weight: bold;
}

.font-futura-pt-medium {
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-style: normal;
}



@font-face {
    font-family: 'Myriad Web';
    src: url('../fonts/Myriad-Web.woff2') format('woff2'),
        url('../fonts/Myriad-Web.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.font-myriad-web {
    font-family: 'Myriad Web';
    font-weight: normal;
}



@media (min-width: 1250px) {
    .container {
        width: initial;
        max-width: 1500px;
    }
}
html {
    background-color: #e6e6e6;
}
body {
    font-family: 'PT Sans', sans-serif;
    background-color: white;
    
    @media screen and (min-width: 1249px) {
        max-width: 1500px;
        border-top: 5px solid $peakblue;
        padding: 0px 50px;
        margin: auto;
    }
}

#nav-container {
    transition: 0.5s all;
    @media screen and (min-width: 768px) {
    position: sticky;
    z-index: 1000;
    }
}
body {
    &.scroll-up {
        #nav-container {       
            transform: translateY(0);
            > .container {
                box-shadow: 0 3px 5px rgb(182 182 182 / 75%);
            }
        }
    }

    &.scroll-down {
        #nav-container {
                transform: translateY(-100%);
        }
    } 
}

.sidemenu {
    opacity: 0;
    @media screen and (max-width: 768px) {
        opacity: 1;
    }
}

.btn, .btn:hover, .btn:focus {
    background-color: $peakblue;
}

a:active, a:focus, a:hover {
    color: $peakblue;
}

header {
    .row {
        border-bottom: 0;
    }
    padding-right: 10px;
    .top {
        #logo {
            margin: 0 0;
            padding: 30px 0px;
        }
        position: relative;
        ul {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin-bottom: 0;
        }
        text-transform: uppercase;
        font-weight: 700;
    }
    .iconify {
        color: $peakblue;
        font-size: 25px;
        top: 7px;
        &.sm {
            font-size: 13px;
            top: 3px;
        }
        position: relative;
        margin-right: 5px;
    }
    
    .cart-link {
        position: relative;
        .total {
            position: absolute;
            top: -2px;
            right: -5px;
            font-size: 14px;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            background: black;
            color: #fff;
            text-align: center;
            
        }
    }
}

#menu {
    border-top: 0; 
    border-bottom: 0;
    @media screen and (max-width: 767px) {
        background-color: #e6e6e6;
    }
    .dropdown-toggle {
        font-family: 'Myriad Web';
        @media screen and (max-width: 767px) {
            font-size: 13px;
        }
    }
    #sidemenu-toggle i {
        background-color: $peakblue !important;
    }
    .navbar-header {
        .iconify {
            color: $peakblue;
            font-size: 25px;
            position: relative;
            top: 7px;
        }
        .visible-xs {
            > div {
                border-right: 1px solid #fff;
                line-height: 41px;
                padding: 0px 10px;
            }
            .cart-link {
                padding-right: 20px;
                position: relative;
                .total {
                    position: absolute;
                    top: 2px;
                    right: 6px;
                    font-size: 14px;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    background: black;
                    color: #fff;
                    text-align: center;
                    line-height: 21px;
                    
                }
            }
        }
    }
    .navbar-right {
        &:last-child {
            margin-right: 0 !important;
        }
        margin-bottom: 0;
        #dealer-nav-link {
            background: $peakblue;
            border-bottom: 5px solid #3eb9c5;
            .iconify {
                margin-right: 10px;
                position: relative;
                top: 2px;
            }
            a {
                i {
                    margin-right: 20px;
                }
                color: white;
            }
        }
    }
    .nav {
        > li {
            border-right: none;
            border-left: none;
            border-bottom: 5px solid transparent;
            > a {
                padding: 10px 18px;
                font-family: 'Myriad Web';
                font-weight: 700;
                font-size: 14px;
                @media(min-width: 768px) and (max-width: 993px) {
                    padding: 15px 8px;
                }
            }
            a {
                color: #7b7879;
                font-weight: 700;
                border: none !important;
                &:hover {
                    border: none !important;
                }
            }
            &.active {
                a {
                    color: inherit !important;
                }
                background-color: #ececec;
                border-bottom: 5px solid $peakblue;
            }
            &:hover {
                &:not(.active) {
                border-bottom: 5px solid #bfbfbf !important;
                }
            }
            @media(max-width: 992px) {
                .dropdown-inner a {
                    padding-left: 17px;
                }
            }
            .dropdown-inner li:hover a {
                color: $peakblue !important;
            }
            .dropdown-menu:after {
                background: $peakblue;
            }
            .dropdown-menu {
                border-bottom: 0 !Important;
                a {
                    color: #fff !important;
                }
            }
            
        }
        border-bottom: 1px solid white;
        > .dropdown.full:hover {
            position: static;
        }
        .dropdown-menu {
            background-color: #145f65;
            margin-top: 5px;
            padding: 25px;
                padding-bottom: 40px;
            a {
                color: #fff;
                font-weight: 500;
            }
            ul {
                width: initial;
                padding-right: 20px;
                @media(max-width:821px) {
                    padding-right: 14px;
                }
                display: inline-block;
                position: relative;
                vertical-align: top;
                &:after {
                    content: " ";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 1px;
                    height: 90%;
                    transform: translateY(-50%);
                    background-color: $peakblue;
                }   
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                li {
                    width: initial;
                    display: block;
                    float: none;
                    white-space: nowrap;
                }
            }  
        }
        > .dropdown.full {
            .dropdown-menu {
                margin-top: 0;
                position: absolute;
                background-color: #145f65;
                padding: 25px;
                padding-bottom: 40px;
                a {
                    color: #fff !important;
                    font-weight: 500;
                    &:hover {
                        color: $peakblue !important;
                    }
                }
                
                display:table !important; width: 100% !important; text-align: center; left:0;
            }
            .dropdown-menu>li {
                display: table-cell;
            }
        }
        
    }
}

footer {
    background-color: #333;
    background-image: none;
    color: #fff;
    .footer-header {
        background-color: transparent;
        border: 0;
       
    }
    font-family: 'Myriad Web';
    font-size: 15px;
    h1 {
        background-color: transparent;
        border: 0;
        color: #666;
        font-family: 'futura-pt';
        font-weight: 500;
        font-size: 30px;
    }
    a {
        color: white;
        &:hover {
            color: #00a2b2;
        }
    }
    .footer-bottom {
        background-color: white;
        color : #222;
        a {
            color: #222;
        }
        p {
            margin: 20px auto;
            text-align: center;
        }
        img {
            margin: 40px auto;
        }
    }
    .footer-top {
        padding-bottom: 60px;
    }
    .social {
        border-radius: 50%;
        border: 4px solid #00a2b2;
        display: inline-block;
        margin: 5px;
        i {
            font-size: 26px;
            color: $peakblue;
            &.fa-youtube-play {
                padding: 6px 6px;
            }
            &.fa-instagram {
                padding: 6px 8px;
            }
            &.fa-facebook {
                padding: 6px 12px;
            }
        }
    }

    @media screen and (min-width: 1249px) {
        margin: 0px -50px;
    }
    
}

#footer-images {
    .owl-carousel {
        margin-bottom: 0px;
    }
    .border-top {
        border-top: 1px solid #e1e1e1;
        margin: 0px -50px;
        padding-bottom: 30px;
        margin-top: 100px;
    }
    
}

.sidemenu>ul>li {
    > a {
        font-family: 'Myriad Web';
    }
    &.active {
        background: $peakblue;
    }
    ul.sub_menu {
        li.active, li:hover {
            background-color: $peakblue;
        }
    }
}

.goToTop {
    @media(max-width: 768px) {
        position: relative !important;
    }
}

.goToTop {
    left: 0;
    > .container {
        border-top: 5px solid $peakblue;
        @media screen and (min-width: 1249px) {
        margin: 0px -50px;
        }
        > .row {
            border-top: 1px solid #fff;
        }
        
    }
}

#homeimages {
    figure {
        height: 285px;
        &.large {
            height: 345px;
            @media(max-width: 992px) {
                height: 268px;
            }
        }
        .im-arrow {
            display: none !important;
        }
    }
    figcaption {
        display: inline-block;
        bottom:20px !important;
        left: 50%;
        max-width: 100%;
        transform: translateX(-50%);
        width: initial;
        text-transform: uppercase;
        padding: 0px 35px;
        font-weight: 700;
        background-color: #333;
        opacity: 1;
        height: 68px;
        h2 {
            line-height: 68px;
            font-family: 'futura-pt';
            font-weight: bold;
            font-size: 40px;
        }
    
    }
    .image {
        padding: 5px;
        @media(max-width: 992px) {
            margin-right: 25px;
        }
        @media screen and (max-width: 767px) {
            margin-right: 0px;
    }
        a {
            position: relative;
            overflow: hidden;
            display: block;
           
            &:hover {
                figcaption {
                    z-index: 200;
                    background-color: white;
                    h2 {
                        color: $peakblue;
                        font-weight: 700;
                        
                    }
                }
                &:after {
                    opacity: 0.7;
                    transform: translateY(0%);
                }
            }
            &:after {
                content: " ";
                transform: translateY(100%);
                transition: 0.5s all;
                z-index: 100;
                width: 100%;
                height: 100%;
                background-color: #00a2b2;
                opacity: 0;
                display: block;
                position: absolute;
                top: 0;
            }
        }
    }
}

#welcome-underline {
    height: 5px;
    background: $peakblue;
    width: 218px;
    margin: auto;
}

.common-home .info-block h1 {
    border: none !important;
}

.carousel .carousel-inner .item {
    .carousel-caption { 
        display: inline-block;
        width: fit-content;
        padding: 16px 50px;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
        

        background-color: $peakblue;
        transition: 0.5s all;
        position: absolute;
        bottom: 100px;
        max-width: 80%;
        @media(max-width: 992px) {
            bottom: 35px;
        }
        height: auto !important;
        &:hover {
            background: white;
            h1 {
                color: $peakblue;
            }
        }
        h1 {
            
            line-height: 115% !important;
            
            padding: 0px 40px;
            font-family: 'futura-pt';
            font-weight: bold;
            font-size: 50px;
            @media(max-width: 992px) {
                font-size: 31px;
            }
        }
        @media screen and (max-width: 550px) {
            h1 {
                font-size: 14px;
            }
        }
    }
    .view-range {
        background-color: #ffffff;
        color: #828282;
        border: 3px solid $peakblue;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        padding: 12px 35px;
        text-transform: uppercase;
        font-family: 'futura-pt';
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 2px;
        transition: 0.5s all;
        background-size: 100% 200%;
        background-position: 100% 0;
        background-color: #fff;
        background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),color-stop(50%,#fff),color-stop(50%,$peakblue),to($peakblue));
        background-image: linear-gradient(#fff 0,#fff 50%,$peakblue,$peakblue 100%);
        background-image: -webkit-gradient(linear,left top,left bottom,from(#fff),color-stop(50%,#fff),color-stop(50%,$peakblue,to($peakblue)));
        background-image: linear-gradient(#fff 0,#fff 50%,$peakblue 50%,$peakblue 100%);
        &:hover {
            color: white;
            background-position: 100% 100%;
            background-color: $peakblue;
        }
    }
    &:hover {
        .view-range {
            //transform: translateY(0%) translateX(-50%);
        }
    }
}

.welcome {
    font-size: 20px;
    font-family: 'Myriad Web';
    max-width: 75%;
    margin: auto;
    line-height: 150%;
    h1 {
        font-weight: 500;
        font-family: 'futura-pt';
        font-size: 40px;
    }
    margin-bottom: 20px;
}


.info-block .h1, .info-block h1 {
    background-color: #009aab;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
}

#info {
    background: #00adbb; /* Old browsers */
    background: -moz-linear-gradient(top,  #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #00adbb 0%,#00a3b4 49%,#0097a8 78%,#0096a7 90%,#0095a4 91%,#0194a6 98%,#0093a3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #00adbb 0%,#00a3b4 49%,#0097a8 78%,#0096a7 90%,#0095a4 91%,#0194a6 98%,#0093a3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00adbb', endColorstr='#0093a3',GradientType=0 ); /* IE6-9 */
    margin-bottom: 0;
    padding: 18px 0px;
    h1 {
        background-color: transparent;
        font-weight: 700;
        padding: 0;
        font-weight: bold;
        font-family: 'futura-pt';
        font-size: 50px;
    }
}
#intro {
    padding: 30px 0px;

    color: #fff;
    line-height: 180%;
    font-size: 16px;
    margin-bottom: 80px;
    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
    position: relative;
    z-index: 20;
    p {
        z-index: 25;
        position: relative;
        max-width: 80%;
        font-size: 21px;
        font-family: 'Myriad Web';
        @media screen and (max-width: 768px) {
            max-width: 95%;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-color: #333;
        z-index: 10;
        background: url('/image/intro-bg.png') 100% 0 no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 100% 0;
        @media (max-width: 900px) {
            background-size: cover;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-color: rgba(0,0,0,0.7);
        z-index: 15;
    }
  
}
.container {
    &.contained {
        @media(min-width: 1249px) {
            padding: 0px 100px;
        }
        padding: 0px 15px;
    }

}
.list-group a {
    background-color: white;
}
.list-group a.list-prod-item.active, .list-group a.list-prod-item.active:hover, .list-group a.list-prod-item:hover {
    color: $peakblue;
    padding-top: 7px;
    padding-bottom: 7px;
}
.list-group a.list-group-item.active, .list-group a.list-group-item.active:hover, .list-group a:hover {
    background-color: $peakblue;
    color: white;
}

ul.teams {
    border: 1px solid #ccc;
}




.list-group {
    >a {
        padding-top: 20px;
        padding-bottom: 20px;
        text-transform: uppercase;
        font-weight: 700 !important;
    }
    .products {
        border: 1px solid #ccc;
        margin: 1px 0px;
        > a {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

.list-group > .list-group-item {
    background: #bbb9ba;
}

.list-group-item.child {
    background: white;
}

a.list-group-item.child, .list-group a.active, .list-group a.active:hover {
    color: #666;
    &:not(.child) {
        transition: 0.2s all;
    &:hover {
        color: white !important;
        background: $peakblue !important;
        font-size: 30px;
        font-family: "futura-pt";
        font-weight: 500 !important;
        line-height: 36px;
    }
}
}

.teams > ul > li > a {
    background-color: white !important;
}

.member-details {
    h1 {
        color: #666 !important;
    }
    hr {
        max-width: 130px;
        border-bottom: 3px solid #ccc;
        &.blue {
            border-color: $peakblue !important;
        }
    }
    h4 {
        color: $peakblue;
    }
}

#member {
    font-size: 16px;
    font-family: 'Myriad Web';
    h1 {
        font-size: 40px;
        text-transform: uppercase;
        font-family: 'futura-pt';
        font-weight: bold;
        color: #666;
    }
}

.info-block {
    p {
        max-width: 100%;
        margin: 0;
    }
}

.information-page {
    padding: 0;
    padding-bottom: 100px;
    .information-title {
        font-size: 40px;
        font-family: 'futura-pt';
        font-weight: bold;
    }
   .information-title:after {
       background-color: $peakblue;
       height: 5px;
   }
   p {
       font-size: 16px;
       line-height: 27px;
       font-family: 'Myriad Web';
   }
}

.top {
    .dropdown {
        border-radius: 5px;
        top: 5px;
        padding: 2px 10px;
        > span {
            position: relative;
            top: -4px;            
        }
        .sm {
            margin-left: 5px;
            color: #666;
        }
        > svg {
            top: 0px;
            left: 3px;
            color: #666;
            font-size: 15px;
        }
            font-size: 13px;
    }
    .list-inline {
        a {
            font-family: 'Myriad Web';
            font-weight: bold;
            font-size: 13px;
        }
    }
}


ul.team-list {
    a.list-group-item.top {
        font-size: 30px;
        font-family: 'futura-pt';
        font-weight: 500 !important;
        line-height: 36px;
        border-bottom: 1px solid #fff !important;
        &:hover {
            line-height: 36px !important;
        }
    }
    > li {
        border-bottom: 1px solid #fff !important;
        > a {
            text-transform: uppercase;
            background-color: #c2c1c2 !important;
            font-weight: 700 !important;
            
        }
    }
    .list-group-item.child {
        background: transparent;
        font-size: 16px !important;
        font-family: 'Myriad Web';
        &:hover {
            color: $peakblue;
        }
    }
    .teams {
        &:not(.has-subs) {
            padding-top: 10px !important;
            padding-bottom: 10px !important; 
        }      
        &.has-subs {
            border-top: 2px solid #fff;
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
        }
        a.list-group-item.active {
            background-color: initial !important;
            color: $peakblue;
            font-weight: 700 !important;
        }
        .sub-team {
            padding-top: 10px !important;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            padding-bottom: 10px !important; 
        }
        .sub_teams {            
            border-bottom: 1px solid #fff;
            font-size: 20px !important;
            font-family: 'futura-pt';
            font-weight: 500;
        }
    }
    
}

#teamimages {
    margin-bottom: 100px;
    a:hover>.dld>.caption, a>.dld>.caption{
        bottom: 20px !important;
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
        padding: 0px 35px;
        text-transform: uppercase;
    }
}

.sidemenu>ul>li:hover {
    background-color: $peakblue;
} 

#region-drodown2 {
    margin-left: 0 !important;
}


.toggle-search i {
    color: $peakblue;
    font-size: 19px;
}

.start-search {
    cursor: pointer;
}
#header-search, #mobile-search-bar {
    position: relative;
    input {
        border-radius: 5px;
        border: 3px solid #e6e6e6;
        min-width: 300px;
        @media screen and (max-width: 1250px){
            min-width: 183px;
        }
        
        font-size: 13px;
        height: 35px;
        padding-left: 8px;
        &::placeholder {
            color: #666;
        }
    }
    svg { 
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        color: $peakblue;
        font-size: 19px;
    }
}
#mobile-search-bar {
    position: relative;
    input {
        max-width: 98%;
        margin: 5px auto;
    }
    svg {
        color: $peakblue;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    //transition: 1s all;
    max-height: 0;
    overflow: hidden;
    &.active {        
        max-height:100px;
        overflow: visible;
        .twitter-typeahead {
            width: 100%;
        }
        .tt-menu {
            width: 100%;
            top: 100% !important;
        }
    }
}

body[class^="product-category-"], body[class^=" product-category-"], body.product-search {
    .info-block {
        margin-bottom: -267px;
        @media screen and (max-width: 768px) {
            margin-bottom: -275px;
        }
        border-bottom: 200px solid #f1f1f1;
        @media screen and (max-width: 768px) {
            border-bottom: 135px solid #f1f1f1;
        }
        position: relative;
        &:after {
            content: "";
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0,0,0,0.6);
        }
    }
    #column-left {
        padding-top: 80px;
    }
    .category-header {
        .category-title {
            background: linear-gradient(to bottom, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
            color: #fff;
            text-transform: uppercase;
            padding: 17.5px 40px;
            @media screen and (max-width: 768px) {
                padding: 16px 30px;
            }
            h1 {
                margin: 0;
                font-weight: bold;
                font-size: 50px;
                color: white; 
                font-family: 'futura-pt';
                @media screen and (max-width: 821px) {
                    font-size: 35px;
                } 
            }
        }
        .category-description {
            background-color: white;
            padding: 25px 40px;
            line-height: 130%;
            font-size: 21px;
            font-family: 'Myriad Web';
            @media screen and (max-width: 821px) {
                font-size: 16px;
                padding: 25px 30px;
                line-height: 160%;
            }
        }
    }
    .product-thumb {
        .caption {
            a {
                font-weight: bold;
                font-family: 'Myriad Web';
                font-size: 17px;
            }
        }
        .price {
            background: #333;
            border-radius: 7px;
            display: inline-block;
            padding: 0px 20px 5px 20px;
            color: #fff;
            margin: 0;
            line-height: 100%;
            margin-bottom: 10px;
            a {
                line-height: 100%;
                font-size: 21px;
                color: #fff;
                font-weight: bold;
                font-family: 'Myriad Web';
            }
        }
    }
    
}


body[class^="information-contact"] {
    #intro {
        margin-bottom: 1px;
    }
}
body[class^=" product-product-"], body[class^="product-product-"] {
    #column-left {
        padding-top: 20px;
    }
    #info {
        height: 239px;
        //margin-bottom: -140px;
        //filter: grayscale(100%);
        //border-bottom: 94px solid #f1f1f1;
    }
    .category-header {
        .category-title {
            h1, h3 {
                font-weight: 700;
            }
            background: #00adbb; /* Old browsers */
            background: -moz-linear-gradient(top,  #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  #00adbb 0%,#00a3b4 49%,#0097a8 78%,#0096a7 90%,#0095a4 91%,#0194a6 98%,#0093a3 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  #00adbb 0%,#00a3b4 49%,#0097a8 78%,#0096a7 90%,#0095a4 91%,#0194a6 98%,#0093a3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00adbb', endColorstr='#0093a3',GradientType=0 ); /* IE6-9 */

            color: #fff;
            text-transform: uppercase;
            padding: 25px 20px;
            h3 {
                margin: 0;
                font-size: 40px;
                color: white;
                font-family: 'futura-pt';
                @media screen and (max-width: 768px) {
                    font-size: 35px;
                    font-family: 'futura-pt';
                    font-weight: 700;
                }   
            }
        }
        .category-description {
            background-color: white;
            padding: 25px 40px;
            @media screen and (max-width: 768px) {
                padding: 25px 30px;
            }
        }
    }
    .product-title {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 700;
        font-family: 'futura-pt';
        font-size: 40px;
        @media screen and (max-width: 821px) {
            font-size: 30px;
            margin-top: 20px;
            display: block;
        }
    }
    .price {
        background-color: $peakblue;
        margin-left: 20px;        
        display: inline-block;
        position: relative;
        font-family: 'futura-pt';
        font-weight: bold;
        @media screen and (max-width: 821px) {
            margin-left: 0;
        }
        h2 {
            padding: 0px 10px;
            color: #fff;
            margin: 0;
            font-size: 40px;
        }
    }
    #button-cart {
        background-color: $peakblue;
    }
    #customise-product {
        background-color: $peakblue;
    }
    .row.pl {
        padding-left: 20px;
    }
    @media(max-width:821px) {
        #info {
            height: 232px;
            height: 147px;
            margin-bottom: -82px;
            filter: grayscale(100%);
            border-bottom: 45px solid #f1f1f1;            
        }
        .category-header {
            .category-title {
                background-color: red;
                padding: 12px 20px !important;
            }
        }
        #product-menu {
            margin-top: 30px;
            margin-bottom: 7px;
            .mobile-home {
                background-color: #545253;
                a {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 18px;
                    line-height: 41px;
                }
            }
            .navbar-header {
                background-color: #7b7879;
            }
            .category-name {
                h2 {
                    color: white;
                }
            }
            .btn.navbar-toggle {
                background: $peakblue;
                color: #fff;
            }
            .iconify {
                position: relative;
                top: 2px;
            }
        }
        .row.pl {
            padding-left: 0;
        }        
    }
    @media(max-width:767px) {
        #info {
            margin-bottom: -109px;
        }
    }


    .prod-details {
        label, select, input {
            font-size: 20px;
            font-family: 'Myriad Web';
            font-weight: normal;
        }
        select {
            height: initial;
            background-size: contain;
            padding: 5px 15px;
        }
        li {
            list-style-image: url(/image/ps-bullet.png);
        }
    }
    
    #input-quantity {
        padding: .5em 5px .5em 5px;
        @media screen and (max-width: 768px) {
            max-width: 90%;
        }
    }
    #button-cart {
        font-family: 'futura-pt';
        font-weight: bold;
        font-size: 21px;
    }
    .prod_logos, .prod_colours {
        li {
            list-style: none !important;
        }
    }
    .prod_description, .prod_logos, .prod_information {
        font-size: 16px;
        font-family: 'Myriad Web';
        line-height: 23px;
        h4 {
            margin: 0;
            font-size: 21px;
            font-weight: bold;
            font-family: 'futura-pt' !important;
            display: inline-block;
            margin-top: 10px;
            color: #666;
            &:after {
                content: "";
                width: 100%;
                max-width: 75px;
                height: 5px;
                background-color: #bbb9ba;
                display: block;
                margin-top: 15px;
            }
            @media screen and (max-width: 768px) {
                font-size: 25px;
            }
        }
    }

    #carousel_pro .thumbnail:hover {
        border: 1px solid $peakblue !important;
    }
    .thumbnails .thumbnail:hover {
        border: 1px solid $peakblue !important;
    }

    #product {
        @media screen and (max-width: 768px) {
            border-top: 1px solid #ccc;
            padding-top: 20px;
        }
    }
}

#category-menu {
    > a {
        font-family: "futura-pt";
        font-size: 27px;
        background-color: #c2c1c2;
        border-bottom: 1px solid #fff;
        line-height: 52px;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: normal !important;
        &:hover {
            font-weight: normal;
            background-color: $peakblue;
        }
        &.active {
            background-color: $peakblue;
            border-bottom: 0;
        }
    }
    .products {
        border: 0;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-top: 0;
        margin-top: 0;
        padding-top: 5px;
        background: #fff;
        
        a, a.active {
            font-size: 16px;
            padding: 6px 10px !important;
            color: #666 !important;
            background: white !important;
            font-family: "Myriad Web" !important;
            line-height: 17px;
            &:hover {
                font-size: 16px;
                font-family: "Myriad Web";
            }
            &.active {
                font-weight: 700 !important;
            }
            &.active, &:hover {
                color: $peakblue !important;
            }
        }
    }
}

#contact {
    padding: 0px;
}

#content {
    &.category-page {
        padding: 0;
        @media screen and (max-width: 768px) {
            margin-top: 29px;
        }
    }
    .row.px-15 {
        padding: 0px 15px;
    }
    .row.products {
        border-top: 60px solid transparent;
            /* background: white; */
            padding-top: 30px;
        @media screen and (max-width: 767px) {
            border-top: 0;
        }
    }
}

.prod-details {
    .prod_information {
        ul {
        list-style-image: url('/image/ps-bullet.png');
        }
    }
}

#menu .dropdown-menu{
    border-left: 0;
    border-right: 0;
    &:after {
        background: $peakblue;
        width: 100%;
                left: 0;
                bottom: 0;
    }
}

#dealers  {
    font-family: 'Myriad Web';
    font-size: 16px;
    a, h2 {
        color: $peakblue;
    }
    h2 {
        font-size: 40px;
        text-transform: uppercase;
        font-family: 'futura-pt';
        font-weight: bold;
    }
}
#dealers-list {
    .countries {
        border: 1px solid #ccc;
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }
    > li {
        border-bottom: 1px solid #fff !important;
        > a {
            font-family: 'futura-pt';
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 0;
        }
    }
    a.list-group-item.child {
        font-size: 16px;
        font-family: 'Myriad Web';
    }
    a.list-group-item.active.child {
        background-color: white;
        color: $peakblue !important;
        font-weight: 700;
    }
    a.list-group-item.active:hover, a:hover {
        //background-color: white !important;
        color: white !important;
    }
    a.list-group-item.child:hover {
        color: $peakblue !important;
        background-color: white !important;
    }
}
#teamimages {
    .caption {
        height: 68px;
        z-index: 20;
    }
    h2 {
    line-height: 68px;
    font-family: "futura-pt";
    font-weight: bold;
    font-size: 40px;
    }
    .t-image {
        a {
            position: relative;
            display: block;
            overflow: hidden;
            height: 285px;
            img {
                object-fit: cover;
                height: 285px;
                width: 100%;
            }
            &:after {
                content: " ";
                z-index: 10;
                opacity: 0.7;
                transition: 0.5s all;
                background-color: $peakblue;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height:100%;
                transform: translateY(100%);
                
      
            }
            &:hover {
                .caption {
                    background-color: white;
                }
                h2 {
                    color: $peakblue;
                }
                &:after {
                    transform: translateY(0%);
                }
            }
        }
    }
}
#product-menu .names .category-name h2 {
    color: $peakblue;
}
#product-menu .nav>li>a.child.active {
    color: $peakblue;
}
#product-menu .nav>li.active, #product-menu .nav>li:hover {
    background-color: $peakblue;
    a {color: $peakblue; }
}

#product-menu .navbar-header {
    border: 0;
}

.dportal-dportal {
    #homeimages {
        .image {
            padding: 20px;
        }
    }
    #menu .nav>li.active {
        background: #ececec;
    }
    font-family: 'Myriad Web';
}
#dimages .allimages {
    background-color: $peakblue;
}
.prod_description {
    h1 {
        color: $peakblue;
    }
}
.modal-dialog {
    font-family: 'Myriad Web';
    h2 {
        font-family: 'futura-pt';
    }
}

@media screen and (max-width: 767px) {
    header .top #logo {
        padding: 15px 0px;
    }
    .carousel .carousel-inner .item .carousel-caption {
        white-space: normal;
        
        h1 {
            font-size: 31px;
            padding: 10px 10px;
        }
    }
    #homeimages figure.large, #homeimages figure {
        height: 268px;
    }
    #product-menu {
        margin-top: 45px;
        background-color: #7b7879;
        text-transform: uppercase;
        .names {
            .category-name h2 {
                color: white;
                font-family: "futura-pt";
                
                @media screen and (max-width: 768px) {
                    font-size: 30px;
                }
                @media screen and (max-width: 548px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 420px) {
                    font-size: 12px;
                }
            }
        }
        
    }

    .category-image {
        overflow: hidden;
        position: relative;
        box-shadow: inset 0 20px 18px -7px rgba(92, 92, 92, 0.6);
        
    }
}
@media(max-width:821px) {
    .category-image {
    height: 200px !important;
    }
}
#product-menu { 
    &.no-back {
        .names {
            position: absolute;
        left: 20px !important;
        right: initial;
        }
    }
    li {
        a {
            font-family: 'Myriad Web';
        }
    }
    li.active {
        background-color: transparent !Important;
        a {
            color: $peakblue !important;
        }
    }
    .btn-navbar {
        background-color: $peakblue !important;
        color: #fff;
        border: 0;
        @media screen and (max-width: 768px) {
            i {
                line-height: 32px;
            }
        }
    }
    min-height: 0;
    
}

#nav-container, header, #myCarousel {
    @media screen and (min-width: 767px) and (max-width: 782px) {
        padding-left: 15px;
        padding-right: 15px;
        .container {
            width: 730px;
        }
    }
}
#product-menu .nav>li>a {
    font-weight: normal !important;
    text-transform: uppercase;
    font-size: 19px;
    padding: 3px 10px!important;
    font-family: "Myriad Web";
}

#product-menu.dealer-menu {
    a {
        padding: 3px 10px !important;
        &.active {
            background-color: #00a2b2;
            color: white !important;
        }
    }
    .top-level {
        &.active {
            a {
                padding: 8px 12px !important;
                background-color: #00a2b2;
                color: white !important;
                font-size: 25px;
            }
        }
    }
}
#customiser-app {
    .order-button {
        background-color: $peakblue !important;
    }
}
#column-left {
    .blog_item, .blog_title { 
        a { 
            color: $peakblue !important; 
        }
    } 
}
body[class^="blog-"], body[class^=" blog-"] {
    font-family: 'Myriad Web';
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background: #00a2b2 !important;
    border-color: #00a2b2 !important;
}


.form-control:focus {
    border-color: $peakblue;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgb(43 162 178);
}