@import url("https://use.typekit.net/ctf3oer.css");
.font-futura-pt-book {
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

html {
  overflow-x: hidden;
}

.font-futura-pt-demi {
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.font-futura-pt {
  font-family: "futura-pt", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.font-futura-pt-bold {
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.font-futura-pt-heavy {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.font-futura-pt-extra {
  font-family: "futura-pt", sans-serif;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Myriad Web";
  src: url("../fonts/Myriad-BdWeb.woff2") format("woff2"), url("../fonts/Myriad-BdWeb.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.font-myriad-web-bold {
  font-family: "Myriad Web";
  font-weight: bold;
}

.font-futura-pt-medium {
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Myriad Web";
  src: url("../fonts/Myriad-Web.woff2") format("woff2"), url("../fonts/Myriad-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.font-myriad-web {
  font-family: "Myriad Web";
  font-weight: normal;
}

@media (min-width: 1250px) {
  .container {
    width: initial;
    max-width: 1500px;
  }
}
html {
  background-color: #e6e6e6;
}

body {
  font-family: "PT Sans", sans-serif;
  background-color: white;
}
@media screen and (min-width: 1249px) {
  body {
    max-width: 1500px;
    border-top: 5px solid #00a2b2;
    padding: 0px 50px;
    margin: auto;
  }
}

#nav-container {
  transition: 0.5s all;
}
@media screen and (min-width: 768px) {
  #nav-container {
    position: sticky;
    z-index: 1000;
  }
}

body.scroll-up #nav-container {
  transform: translateY(0);
}
body.scroll-up #nav-container > .container {
  box-shadow: 0 3px 5px rgba(182, 182, 182, 0.75);
}
body.scroll-down #nav-container {
  transform: translateY(-100%);
}

.sidemenu {
  opacity: 0;
}
@media screen and (max-width: 768px) {
  .sidemenu {
    opacity: 1;
  }
}

.btn, .btn:hover, .btn:focus {
  background-color: #00a2b2;
}

a:active, a:focus, a:hover {
  color: #00a2b2;
}

header {
  padding-right: 10px;
}
header .row {
  border-bottom: 0;
}
header .top {
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
}
header .top #logo {
  margin: 0 0;
  padding: 30px 0px;
}
header .top ul {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin-bottom: 0;
}
header .iconify {
  color: #00a2b2;
  font-size: 25px;
  top: 7px;
  position: relative;
  margin-right: 5px;
}
header .iconify.sm {
  font-size: 13px;
  top: 3px;
}
header .cart-link {
  position: relative;
}
header .cart-link .total {
  position: absolute;
  top: -2px;
  right: -5px;
  font-size: 14px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: black;
  color: #fff;
  text-align: center;
}

#menu {
  border-top: 0;
  border-bottom: 0;
}
@media screen and (max-width: 767px) {
  #menu {
    background-color: #e6e6e6;
  }
}
#menu .dropdown-toggle {
  font-family: "Myriad Web";
}
@media screen and (max-width: 767px) {
  #menu .dropdown-toggle {
    font-size: 13px;
  }
}
#menu #sidemenu-toggle i {
  background-color: #00a2b2 !important;
}
#menu .navbar-header .iconify {
  color: #00a2b2;
  font-size: 25px;
  position: relative;
  top: 7px;
}
#menu .navbar-header .visible-xs > div {
  border-right: 1px solid #fff;
  line-height: 41px;
  padding: 0px 10px;
}
#menu .navbar-header .visible-xs .cart-link {
  padding-right: 20px;
  position: relative;
}
#menu .navbar-header .visible-xs .cart-link .total {
  position: absolute;
  top: 2px;
  right: 6px;
  font-size: 14px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: black;
  color: #fff;
  text-align: center;
  line-height: 21px;
}
#menu .navbar-right {
  margin-bottom: 0;
}
#menu .navbar-right:last-child {
  margin-right: 0 !important;
}
#menu .navbar-right #dealer-nav-link {
  background: #00a2b2;
  border-bottom: 5px solid #3eb9c5;
}
#menu .navbar-right #dealer-nav-link .iconify {
  margin-right: 10px;
  position: relative;
  top: 2px;
}
#menu .navbar-right #dealer-nav-link a {
  color: white;
}
#menu .navbar-right #dealer-nav-link a i {
  margin-right: 20px;
}
#menu .nav {
  border-bottom: 1px solid white;
}
#menu .nav > li {
  border-right: none;
  border-left: none;
  border-bottom: 5px solid transparent;
}
#menu .nav > li > a {
  padding: 10px 18px;
  font-family: "Myriad Web";
  font-weight: 700;
  font-size: 14px;
}
@media (min-width: 768px) and (max-width: 993px) {
  #menu .nav > li > a {
    padding: 15px 8px;
  }
}
#menu .nav > li a {
  color: #7b7879;
  font-weight: 700;
  border: none !important;
}
#menu .nav > li a:hover {
  border: none !important;
}
#menu .nav > li.active {
  background-color: #ececec;
  border-bottom: 5px solid #00a2b2;
}
#menu .nav > li.active a {
  color: inherit !important;
}
#menu .nav > li:hover:not(.active) {
  border-bottom: 5px solid #bfbfbf !important;
}
@media (max-width: 992px) {
  #menu .nav > li .dropdown-inner a {
    padding-left: 17px;
  }
}
#menu .nav > li .dropdown-inner li:hover a {
  color: #00a2b2 !important;
}
#menu .nav > li .dropdown-menu:after {
  background: #00a2b2;
}
#menu .nav > li .dropdown-menu {
  border-bottom: 0 !important;
}
#menu .nav > li .dropdown-menu a {
  color: #fff !important;
}
#menu .nav > .dropdown.full:hover {
  position: static;
}
#menu .nav .dropdown-menu {
  background-color: #145f65;
  margin-top: 5px;
  padding: 25px;
  padding-bottom: 40px;
}
#menu .nav .dropdown-menu a {
  color: #fff;
  font-weight: 500;
}
#menu .nav .dropdown-menu ul {
  width: initial;
  padding-right: 20px;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
@media (max-width: 821px) {
  #menu .nav .dropdown-menu ul {
    padding-right: 14px;
  }
}
#menu .nav .dropdown-menu ul:after {
  content: " ";
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  height: 90%;
  transform: translateY(-50%);
  background-color: #00a2b2;
}
#menu .nav .dropdown-menu ul:last-child:after {
  display: none;
}
#menu .nav .dropdown-menu ul li {
  width: initial;
  display: block;
  float: none;
  white-space: nowrap;
}
#menu .nav > .dropdown.full .dropdown-menu {
  margin-top: 0;
  position: absolute;
  background-color: #145f65;
  padding: 25px;
  padding-bottom: 40px;
  display: table !important;
  width: 100% !important;
  text-align: center;
  left: 0;
}
#menu .nav > .dropdown.full .dropdown-menu a {
  color: #fff !important;
  font-weight: 500;
}
#menu .nav > .dropdown.full .dropdown-menu a:hover {
  color: #00a2b2 !important;
}
#menu .nav > .dropdown.full .dropdown-menu > li {
  display: table-cell;
}

footer {
  background-color: #333;
  background-image: none;
  color: #fff;
  font-family: "Myriad Web";
  font-size: 15px;
}
footer .footer-header {
  background-color: transparent;
  border: 0;
}
footer h1 {
  background-color: transparent;
  border: 0;
  color: #666;
  font-family: "futura-pt";
  font-weight: 500;
  font-size: 30px;
}
footer a {
  color: white;
}
footer a:hover {
  color: #00a2b2;
}
footer .footer-bottom {
  background-color: white;
  color: #222;
}
footer .footer-bottom a {
  color: #222;
}
footer .footer-bottom p {
  margin: 20px auto;
  text-align: center;
}
footer .footer-bottom img {
  margin: 40px auto;
}
footer .footer-top {
  padding-bottom: 60px;
}
footer .social {
  border-radius: 50%;
  border: 4px solid #00a2b2;
  display: inline-block;
  margin: 5px;
}
footer .social i {
  font-size: 26px;
  color: #00a2b2;
}
footer .social i.fa-youtube-play {
  padding: 6px 6px;
}
footer .social i.fa-instagram {
  padding: 6px 8px;
}
footer .social i.fa-facebook {
  padding: 6px 12px;
}
@media screen and (min-width: 1249px) {
  footer {
    margin: 0px -50px;
  }
}

#footer-images .owl-carousel {
  margin-bottom: 0px;
}
#footer-images .border-top {
  border-top: 1px solid #e1e1e1;
  margin: 0px -50px;
  padding-bottom: 30px;
  margin-top: 100px;
}

.sidemenu > ul > li > a {
  font-family: "Myriad Web";
}
.sidemenu > ul > li.active {
  background: #00a2b2;
}
.sidemenu > ul > li ul.sub_menu li.active, .sidemenu > ul > li ul.sub_menu li:hover {
  background-color: #00a2b2;
}

@media (max-width: 768px) {
  .goToTop {
    position: relative !important;
  }
}

.goToTop {
  left: 0;
}
.goToTop > .container {
  border-top: 5px solid #00a2b2;
}
@media screen and (min-width: 1249px) {
  .goToTop > .container {
    margin: 0px -50px;
  }
}
.goToTop > .container > .row {
  border-top: 1px solid #fff;
}

#homeimages figure {
  height: 285px;
}
#homeimages figure.large {
  height: 345px;
}
@media (max-width: 992px) {
  #homeimages figure.large {
    height: 268px;
  }
}
#homeimages figure .im-arrow {
  display: none !important;
}
#homeimages figcaption {
  display: inline-block;
  bottom: 20px !important;
  left: 50%;
  max-width: 100%;
  transform: translateX(-50%);
  width: initial;
  text-transform: uppercase;
  padding: 0px 35px;
  font-weight: 700;
  background-color: #333;
  opacity: 1;
  height: 68px;
}
#homeimages figcaption h2 {
  line-height: 68px;
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 40px;
}
#homeimages .image {
  padding: 5px;
}
@media (max-width: 992px) {
  #homeimages .image {
    margin-right: 25px;
  }
}
@media screen and (max-width: 767px) {
  #homeimages .image {
    margin-right: 0px;
  }
}
#homeimages .image a {
  position: relative;
  overflow: hidden;
  display: block;
}
#homeimages .image a:hover figcaption {
  z-index: 200;
  background-color: white;
}
#homeimages .image a:hover figcaption h2 {
  color: #00a2b2;
  font-weight: 700;
}
#homeimages .image a:hover:after {
  opacity: 0.7;
  transform: translateY(0%);
}
#homeimages .image a:after {
  content: " ";
  transform: translateY(100%);
  transition: 0.5s all;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #00a2b2;
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
}

#welcome-underline {
  height: 5px;
  background: #00a2b2;
  width: 218px;
  margin: auto;
}

.common-home .info-block h1 {
  border: none !important;
}

.carousel .carousel-inner .item .carousel-caption {
  display: inline-block;
  width: fit-content;
  padding: 16px 50px;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #00a2b2;
  transition: 0.5s all;
  position: absolute;
  bottom: 100px;
  max-width: 80%;
  height: auto !important;
}
@media (max-width: 992px) {
  .carousel .carousel-inner .item .carousel-caption {
    bottom: 35px;
  }
}
.carousel .carousel-inner .item .carousel-caption:hover {
  background: white;
}
.carousel .carousel-inner .item .carousel-caption:hover h1 {
  color: #00a2b2;
}
.carousel .carousel-inner .item .carousel-caption h1 {
  line-height: 115% !important;
  padding: 0px 40px;
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 50px;
}
@media (max-width: 992px) {
  .carousel .carousel-inner .item .carousel-caption h1 {
    font-size: 31px;
  }
}
@media screen and (max-width: 550px) {
  .carousel .carousel-inner .item .carousel-caption h1 {
    font-size: 14px;
  }
}
.carousel .carousel-inner .item .view-range {
  background-color: #ffffff;
  color: #828282;
  border: 3px solid #00a2b2;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 35px;
  text-transform: uppercase;
  font-family: "futura-pt";
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 2px;
  transition: 0.5s all;
  background-size: 100% 200%;
  background-position: 100% 0;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(50%, #fff), color-stop(50%, #00a2b2), to(#00a2b2));
  background-image: linear-gradient(#fff 0, #fff 50%, #00a2b2, #00a2b2 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(50%, #fff), color-stop(50%, #00a2b2, to(#00a2b2)));
  background-image: linear-gradient(#fff 0, #fff 50%, #00a2b2 50%, #00a2b2 100%);
}
.carousel .carousel-inner .item .view-range:hover {
  color: white;
  background-position: 100% 100%;
  background-color: #00a2b2;
}
.welcome {
  font-size: 20px;
  font-family: "Myriad Web";
  max-width: 75%;
  margin: auto;
  line-height: 150%;
  margin-bottom: 20px;
}
.welcome h1 {
  font-weight: 500;
  font-family: "futura-pt";
  font-size: 40px;
}

.info-block .h1, .info-block h1 {
  background-color: #009aab;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
}

#info {
  background: #00adbb;
  /* Old browsers */
  background: -moz-linear-gradient(top, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00adbb", endColorstr="#0093a3",GradientType=0 );
  /* IE6-9 */
  margin-bottom: 0;
  padding: 18px 0px;
}
#info h1 {
  background-color: transparent;
  font-weight: 700;
  padding: 0;
  font-weight: bold;
  font-family: "futura-pt";
  font-size: 50px;
}

#intro {
  padding: 30px 0px;
  color: #fff;
  line-height: 180%;
  font-size: 16px;
  margin-bottom: 80px;
  position: relative;
  z-index: 20;
}
@media screen and (max-width: 768px) {
  #intro {
    margin-bottom: 10px;
  }
}
#intro p {
  z-index: 25;
  position: relative;
  max-width: 80%;
  font-size: 21px;
  font-family: "Myriad Web";
}
@media screen and (max-width: 768px) {
  #intro p {
    max-width: 95%;
  }
}
#intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  z-index: 10;
  background: url("/image/intro-bg.png") 100% 0 no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0;
}
@media (max-width: 900px) {
  #intro::before {
    background-size: cover;
  }
}
#intro::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 15;
}

.container.contained {
  padding: 0px 15px;
}
@media (min-width: 1249px) {
  .container.contained {
    padding: 0px 100px;
  }
}

.list-group a {
  background-color: white;
}

.list-group a.list-prod-item.active, .list-group a.list-prod-item.active:hover, .list-group a.list-prod-item:hover {
  color: #00a2b2;
  padding-top: 7px;
  padding-bottom: 7px;
}

.list-group a.list-group-item.active, .list-group a.list-group-item.active:hover, .list-group a:hover {
  background-color: #00a2b2;
  color: white;
}

ul.teams {
  border: 1px solid #ccc;
}

.list-group > a {
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700 !important;
}
.list-group .products {
  border: 1px solid #ccc;
  margin: 1px 0px;
}
.list-group .products > a {
  padding-top: 7px;
  padding-bottom: 7px;
}

.list-group > .list-group-item {
  background: #bbb9ba;
}

.list-group-item.child {
  background: white;
}

a.list-group-item.child, .list-group a.active, .list-group a.active:hover {
  color: #666;
}
a.list-group-item.child:not(.child), .list-group a.active:not(.child), .list-group a.active:hover:not(.child) {
  transition: 0.2s all;
}
a.list-group-item.child:not(.child):hover, .list-group a.active:not(.child):hover, .list-group a.active:hover:not(.child):hover {
  color: white !important;
  background: #00a2b2 !important;
  font-size: 30px;
  font-family: "futura-pt";
  font-weight: 500 !important;
  line-height: 36px;
}

.teams > ul > li > a {
  background-color: white !important;
}

.member-details h1 {
  color: #666 !important;
}
.member-details hr {
  max-width: 130px;
  border-bottom: 3px solid #ccc;
}
.member-details hr.blue {
  border-color: #00a2b2 !important;
}
.member-details h4 {
  color: #00a2b2;
}

#member {
  font-size: 16px;
  font-family: "Myriad Web";
}
#member h1 {
  font-size: 40px;
  text-transform: uppercase;
  font-family: "futura-pt";
  font-weight: bold;
  color: #666;
}

.info-block p {
  max-width: 100%;
  margin: 0;
}

.information-page {
  padding: 0;
  padding-bottom: 100px;
}
.information-page .information-title {
  font-size: 40px;
  font-family: "futura-pt";
  font-weight: bold;
}
.information-page .information-title:after {
  background-color: #00a2b2;
  height: 5px;
}
.information-page p {
  font-size: 16px;
  line-height: 27px;
  font-family: "Myriad Web";
}

.top .dropdown {
  border-radius: 5px;
  top: 5px;
  padding: 2px 10px;
  font-size: 13px;
}
.top .dropdown > span {
  position: relative;
  top: -4px;
}
.top .dropdown .sm {
  margin-left: 5px;
  color: #666;
}
.top .dropdown > svg {
  top: 0px;
  left: 3px;
  color: #666;
  font-size: 15px;
}
.top .list-inline a {
  font-family: "Myriad Web";
  font-weight: bold;
  font-size: 13px;
}

ul.team-list a.list-group-item.top {
  font-size: 30px;
  font-family: "futura-pt";
  font-weight: 500 !important;
  line-height: 36px;
  border-bottom: 1px solid #fff !important;
}
ul.team-list a.list-group-item.top:hover {
  line-height: 36px !important;
}
ul.team-list > li {
  border-bottom: 1px solid #fff !important;
}
ul.team-list > li > a {
  text-transform: uppercase;
  background-color: #c2c1c2 !important;
  font-weight: 700 !important;
}
ul.team-list .list-group-item.child {
  background: transparent;
  font-size: 16px !important;
  font-family: "Myriad Web";
}
ul.team-list .list-group-item.child:hover {
  color: #00a2b2;
}
ul.team-list .teams:not(.has-subs) {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
ul.team-list .teams.has-subs {
  border-top: 2px solid #fff;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
ul.team-list .teams a.list-group-item.active {
  background-color: initial !important;
  color: #00a2b2;
  font-weight: 700 !important;
}
ul.team-list .teams .sub-team {
  padding-top: 10px !important;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding-bottom: 10px !important;
}
ul.team-list .teams .sub_teams {
  border-bottom: 1px solid #fff;
  font-size: 20px !important;
  font-family: "futura-pt";
  font-weight: 500;
}

#teamimages {
  margin-bottom: 100px;
}
#teamimages a:hover > .dld > .caption, #teamimages a > .dld > .caption {
  bottom: 20px !important;
  width: fit-content;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 35px;
  text-transform: uppercase;
}

.sidemenu > ul > li:hover {
  background-color: #00a2b2;
}

#region-drodown2 {
  margin-left: 0 !important;
}

.toggle-search i {
  color: #00a2b2;
  font-size: 19px;
}

.start-search {
  cursor: pointer;
}

#header-search, #mobile-search-bar {
  position: relative;
}
#header-search input, #mobile-search-bar input {
  border-radius: 5px;
  border: 3px solid #e6e6e6;
  min-width: 300px;
  font-size: 13px;
  height: 35px;
  padding-left: 8px;
}
@media screen and (max-width: 1250px) {
  #header-search input, #mobile-search-bar input {
    min-width: 183px;
  }
}
#header-search input::placeholder, #mobile-search-bar input::placeholder {
  color: #666;
}
#header-search svg, #mobile-search-bar svg {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  color: #00a2b2;
  font-size: 19px;
}

#mobile-search-bar {
  position: relative;
  max-height: 0;
  overflow: hidden;
}
#mobile-search-bar input {
  max-width: 98%;
  margin: 5px auto;
}
#mobile-search-bar svg {
  color: #00a2b2;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
#mobile-search-bar.active {
  max-height: 100px;
  overflow: visible;
}
#mobile-search-bar.active .twitter-typeahead {
  width: 100%;
}
#mobile-search-bar.active .tt-menu {
  width: 100%;
  top: 100% !important;
}

body[class^=product-category-] .info-block, body[class^=" product-category-"] .info-block, body.product-search .info-block {
  margin-bottom: -267px;
  border-bottom: 200px solid #f1f1f1;
  position: relative;
}
@media screen and (max-width: 768px) {
  body[class^=product-category-] .info-block, body[class^=" product-category-"] .info-block, body.product-search .info-block {
    margin-bottom: -275px;
  }
}
@media screen and (max-width: 768px) {
  body[class^=product-category-] .info-block, body[class^=" product-category-"] .info-block, body.product-search .info-block {
    border-bottom: 135px solid #f1f1f1;
  }
}
body[class^=product-category-] .info-block:after, body[class^=" product-category-"] .info-block:after, body.product-search .info-block:after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
}
body[class^=product-category-] #column-left, body[class^=" product-category-"] #column-left, body.product-search #column-left {
  padding-top: 80px;
}
body[class^=product-category-] .category-header .category-title, body[class^=" product-category-"] .category-header .category-title, body.product-search .category-header .category-title {
  background: linear-gradient(to bottom, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
  color: #fff;
  text-transform: uppercase;
  padding: 17.5px 40px;
}
@media screen and (max-width: 768px) {
  body[class^=product-category-] .category-header .category-title, body[class^=" product-category-"] .category-header .category-title, body.product-search .category-header .category-title {
    padding: 16px 30px;
  }
}
body[class^=product-category-] .category-header .category-title h1, body[class^=" product-category-"] .category-header .category-title h1, body.product-search .category-header .category-title h1 {
  margin: 0;
  font-weight: bold;
  font-size: 50px;
  color: white;
  font-family: "futura-pt";
}
@media screen and (max-width: 821px) {
  body[class^=product-category-] .category-header .category-title h1, body[class^=" product-category-"] .category-header .category-title h1, body.product-search .category-header .category-title h1 {
    font-size: 35px;
  }
}
body[class^=product-category-] .category-header .category-description, body[class^=" product-category-"] .category-header .category-description, body.product-search .category-header .category-description {
  background-color: white;
  padding: 25px 40px;
  line-height: 130%;
  font-size: 21px;
  font-family: "Myriad Web";
}
@media screen and (max-width: 821px) {
  body[class^=product-category-] .category-header .category-description, body[class^=" product-category-"] .category-header .category-description, body.product-search .category-header .category-description {
    font-size: 16px;
    padding: 25px 30px;
    line-height: 160%;
  }
}
body[class^=product-category-] .product-thumb .caption a, body[class^=" product-category-"] .product-thumb .caption a, body.product-search .product-thumb .caption a {
  font-weight: bold;
  font-family: "Myriad Web";
  font-size: 17px;
}
body[class^=product-category-] .product-thumb .price, body[class^=" product-category-"] .product-thumb .price, body.product-search .product-thumb .price {
  background: #333;
  border-radius: 7px;
  display: inline-block;
  padding: 0px 20px 5px 20px;
  color: #fff;
  margin: 0;
  line-height: 100%;
  margin-bottom: 10px;
}
body[class^=product-category-] .product-thumb .price a, body[class^=" product-category-"] .product-thumb .price a, body.product-search .product-thumb .price a {
  line-height: 100%;
  font-size: 21px;
  color: #fff;
  font-weight: bold;
  font-family: "Myriad Web";
}

body[class^=information-contact] #intro {
  margin-bottom: 1px;
}

body[class^=" product-product-"] #column-left, body[class^=product-product-] #column-left {
  padding-top: 20px;
}
body[class^=" product-product-"] #info, body[class^=product-product-] #info {
  height: 239px;
}
body[class^=" product-product-"] .category-header .category-title, body[class^=product-product-] .category-header .category-title {
  background: #00adbb;
  /* Old browsers */
  background: -moz-linear-gradient(top, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00adbb 0%, #00a3b4 49%, #0097a8 78%, #0096a7 90%, #0095a4 91%, #0194a6 98%, #0093a3 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00adbb", endColorstr="#0093a3",GradientType=0 );
  /* IE6-9 */
  color: #fff;
  text-transform: uppercase;
  padding: 25px 20px;
}
body[class^=" product-product-"] .category-header .category-title h1, body[class^=" product-product-"] .category-header .category-title h3, body[class^=product-product-] .category-header .category-title h1, body[class^=product-product-] .category-header .category-title h3 {
  font-weight: 700;
}
body[class^=" product-product-"] .category-header .category-title h3, body[class^=product-product-] .category-header .category-title h3 {
  margin: 0;
  font-size: 40px;
  color: white;
  font-family: "futura-pt";
}
@media screen and (max-width: 768px) {
  body[class^=" product-product-"] .category-header .category-title h3, body[class^=product-product-] .category-header .category-title h3 {
    font-size: 35px;
    font-family: "futura-pt";
    font-weight: 700;
  }
}
body[class^=" product-product-"] .category-header .category-description, body[class^=product-product-] .category-header .category-description {
  background-color: white;
  padding: 25px 40px;
}
@media screen and (max-width: 768px) {
  body[class^=" product-product-"] .category-header .category-description, body[class^=product-product-] .category-header .category-description {
    padding: 25px 30px;
  }
}
body[class^=" product-product-"] .product-title, body[class^=product-product-] .product-title {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "futura-pt";
  font-size: 40px;
}
@media screen and (max-width: 821px) {
  body[class^=" product-product-"] .product-title, body[class^=product-product-] .product-title {
    font-size: 30px;
    margin-top: 20px;
    display: block;
  }
}
body[class^=" product-product-"] .price, body[class^=product-product-] .price {
  background-color: #00a2b2;
  margin-left: 20px;
  display: inline-block;
  position: relative;
  font-family: "futura-pt";
  font-weight: bold;
}
@media screen and (max-width: 821px) {
  body[class^=" product-product-"] .price, body[class^=product-product-] .price {
    margin-left: 0;
  }
}
body[class^=" product-product-"] .price h2, body[class^=product-product-] .price h2 {
  padding: 0px 10px;
  color: #fff;
  margin: 0;
  font-size: 40px;
}
body[class^=" product-product-"] #button-cart, body[class^=product-product-] #button-cart {
  background-color: #00a2b2;
}
body[class^=" product-product-"] #customise-product, body[class^=product-product-] #customise-product {
  background-color: #00a2b2;
}
body[class^=" product-product-"] .row.pl, body[class^=product-product-] .row.pl {
  padding-left: 20px;
}
@media (max-width: 821px) {
  body[class^=" product-product-"] #info, body[class^=product-product-] #info {
    height: 232px;
    height: 147px;
    margin-bottom: -82px;
    filter: grayscale(100%);
    border-bottom: 45px solid #f1f1f1;
  }
  body[class^=" product-product-"] .category-header .category-title, body[class^=product-product-] .category-header .category-title {
    background-color: red;
    padding: 12px 20px !important;
  }
  body[class^=" product-product-"] #product-menu, body[class^=product-product-] #product-menu {
    margin-top: 30px;
    margin-bottom: 7px;
  }
  body[class^=" product-product-"] #product-menu .mobile-home, body[class^=product-product-] #product-menu .mobile-home {
    background-color: #545253;
  }
  body[class^=" product-product-"] #product-menu .mobile-home a, body[class^=product-product-] #product-menu .mobile-home a {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 41px;
  }
  body[class^=" product-product-"] #product-menu .navbar-header, body[class^=product-product-] #product-menu .navbar-header {
    background-color: #7b7879;
  }
  body[class^=" product-product-"] #product-menu .category-name h2, body[class^=product-product-] #product-menu .category-name h2 {
    color: white;
  }
  body[class^=" product-product-"] #product-menu .btn.navbar-toggle, body[class^=product-product-] #product-menu .btn.navbar-toggle {
    background: #00a2b2;
    color: #fff;
  }
  body[class^=" product-product-"] #product-menu .iconify, body[class^=product-product-] #product-menu .iconify {
    position: relative;
    top: 2px;
  }
  body[class^=" product-product-"] .row.pl, body[class^=product-product-] .row.pl {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  body[class^=" product-product-"] #info, body[class^=product-product-] #info {
    margin-bottom: -109px;
  }
}
body[class^=" product-product-"] .prod-details label, body[class^=" product-product-"] .prod-details select, body[class^=" product-product-"] .prod-details input, body[class^=product-product-] .prod-details label, body[class^=product-product-] .prod-details select, body[class^=product-product-] .prod-details input {
  font-size: 20px;
  font-family: "Myriad Web";
  font-weight: normal;
}
body[class^=" product-product-"] .prod-details select, body[class^=product-product-] .prod-details select {
  height: initial;
  background-size: contain;
  padding: 5px 15px;
}
body[class^=" product-product-"] .prod-details li, body[class^=product-product-] .prod-details li {
  list-style-image: url(/image/ps-bullet.png);
}
body[class^=" product-product-"] #input-quantity, body[class^=product-product-] #input-quantity {
  padding: 0.5em 5px 0.5em 5px;
}
@media screen and (max-width: 768px) {
  body[class^=" product-product-"] #input-quantity, body[class^=product-product-] #input-quantity {
    max-width: 90%;
  }
}
body[class^=" product-product-"] #button-cart, body[class^=product-product-] #button-cart {
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 21px;
}
body[class^=" product-product-"] .prod_logos li, body[class^=" product-product-"] .prod_colours li, body[class^=product-product-] .prod_logos li, body[class^=product-product-] .prod_colours li {
  list-style: none !important;
}
body[class^=" product-product-"] .prod_description, body[class^=" product-product-"] .prod_logos, body[class^=" product-product-"] .prod_information, body[class^=product-product-] .prod_description, body[class^=product-product-] .prod_logos, body[class^=product-product-] .prod_information {
  font-size: 16px;
  font-family: "Myriad Web";
  line-height: 23px;
}
body[class^=" product-product-"] .prod_description h4, body[class^=" product-product-"] .prod_logos h4, body[class^=" product-product-"] .prod_information h4, body[class^=product-product-] .prod_description h4, body[class^=product-product-] .prod_logos h4, body[class^=product-product-] .prod_information h4 {
  margin: 0;
  font-size: 21px;
  font-weight: bold;
  font-family: "futura-pt" !important;
  display: inline-block;
  margin-top: 10px;
  color: #666;
}
body[class^=" product-product-"] .prod_description h4:after, body[class^=" product-product-"] .prod_logos h4:after, body[class^=" product-product-"] .prod_information h4:after, body[class^=product-product-] .prod_description h4:after, body[class^=product-product-] .prod_logos h4:after, body[class^=product-product-] .prod_information h4:after {
  content: "";
  width: 100%;
  max-width: 75px;
  height: 5px;
  background-color: #bbb9ba;
  display: block;
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  body[class^=" product-product-"] .prod_description h4, body[class^=" product-product-"] .prod_logos h4, body[class^=" product-product-"] .prod_information h4, body[class^=product-product-] .prod_description h4, body[class^=product-product-] .prod_logos h4, body[class^=product-product-] .prod_information h4 {
    font-size: 25px;
  }
}
body[class^=" product-product-"] #carousel_pro .thumbnail:hover, body[class^=product-product-] #carousel_pro .thumbnail:hover {
  border: 1px solid #00a2b2 !important;
}
body[class^=" product-product-"] .thumbnails .thumbnail:hover, body[class^=product-product-] .thumbnails .thumbnail:hover {
  border: 1px solid #00a2b2 !important;
}
@media screen and (max-width: 768px) {
  body[class^=" product-product-"] #product, body[class^=product-product-] #product {
    border-top: 1px solid #ccc;
    padding-top: 20px;
  }
}

#category-menu > a {
  font-family: "futura-pt";
  font-size: 27px;
  background-color: #c2c1c2;
  border-bottom: 1px solid #fff;
  line-height: 52px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: normal !important;
}
#category-menu > a:hover {
  font-weight: normal;
  background-color: #00a2b2;
}
#category-menu > a.active {
  background-color: #00a2b2;
  border-bottom: 0;
}
#category-menu .products {
  border: 0;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 0;
  margin-top: 0;
  padding-top: 5px;
  background: #fff;
}
#category-menu .products a, #category-menu .products a.active {
  font-size: 16px;
  padding: 6px 10px !important;
  color: #666 !important;
  background: white !important;
  font-family: "Myriad Web" !important;
  line-height: 17px;
}
#category-menu .products a:hover, #category-menu .products a.active:hover {
  font-size: 16px;
  font-family: "Myriad Web";
}
#category-menu .products a.active, #category-menu .products a.active.active {
  font-weight: 700 !important;
}
#category-menu .products a.active, #category-menu .products a:hover, #category-menu .products a.active.active, #category-menu .products a.active:hover {
  color: #00a2b2 !important;
}

#contact {
  padding: 0px;
}

#content.category-page {
  padding: 0;
}
@media screen and (max-width: 768px) {
  #content.category-page {
    margin-top: 29px;
  }
}
#content .row.px-15 {
  padding: 0px 15px;
}
#content .row.products {
  border-top: 60px solid transparent;
  /* background: white; */
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  #content .row.products {
    border-top: 0;
  }
}

.prod-details .prod_information ul {
  list-style-image: url("/image/ps-bullet.png");
}

#menu .dropdown-menu {
  border-left: 0;
  border-right: 0;
}
#menu .dropdown-menu:after {
  background: #00a2b2;
  width: 100%;
  left: 0;
  bottom: 0;
}

#dealers {
  font-family: "Myriad Web";
  font-size: 16px;
}
#dealers a, #dealers h2 {
  color: #00a2b2;
}
#dealers h2 {
  font-size: 40px;
  text-transform: uppercase;
  font-family: "futura-pt";
  font-weight: bold;
}

#dealers-list .countries {
  border: 1px solid #ccc;
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}
#dealers-list > li {
  border-bottom: 1px solid #fff !important;
}
#dealers-list > li > a {
  font-family: "futura-pt";
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 0;
}
#dealers-list a.list-group-item.child {
  font-size: 16px;
  font-family: "Myriad Web";
}
#dealers-list a.list-group-item.active.child {
  background-color: white;
  color: #00a2b2 !important;
  font-weight: 700;
}
#dealers-list a.list-group-item.active:hover, #dealers-list a:hover {
  color: white !important;
}
#dealers-list a.list-group-item.child:hover {
  color: #00a2b2 !important;
  background-color: white !important;
}

#teamimages .caption {
  height: 68px;
  z-index: 20;
}
#teamimages h2 {
  line-height: 68px;
  font-family: "futura-pt";
  font-weight: bold;
  font-size: 40px;
}
#teamimages .t-image a {
  position: relative;
  display: block;
  overflow: hidden;
  height: 285px;
}
#teamimages .t-image a img {
  object-fit: cover;
  height: 285px;
  width: 100%;
}
#teamimages .t-image a:after {
  content: " ";
  z-index: 10;
  opacity: 0.7;
  transition: 0.5s all;
  background-color: #00a2b2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
}
#teamimages .t-image a:hover .caption {
  background-color: white;
}
#teamimages .t-image a:hover h2 {
  color: #00a2b2;
}
#teamimages .t-image a:hover:after {
  transform: translateY(0%);
}

#product-menu .names .category-name h2 {
  color: #00a2b2;
}

#product-menu .nav > li > a.child.active {
  color: #00a2b2;
}

#product-menu .nav > li.active, #product-menu .nav > li:hover {
  background-color: #00a2b2;
}
#product-menu .nav > li.active a, #product-menu .nav > li:hover a {
  color: #00a2b2;
}

#product-menu .navbar-header {
  border: 0;
}

.dportal-dportal {
  font-family: "Myriad Web";
}
.dportal-dportal #homeimages .image {
  padding: 20px;
}
.dportal-dportal #menu .nav > li.active {
  background: #ececec;
}

#dimages .allimages {
  background-color: #00a2b2;
}

.prod_description h1 {
  color: #00a2b2;
}

.modal-dialog {
  font-family: "Myriad Web";
}
.modal-dialog h2 {
  font-family: "futura-pt";
}

@media screen and (max-width: 767px) {
  header .top #logo {
    padding: 15px 0px;
  }

  .carousel .carousel-inner .item .carousel-caption {
    white-space: normal;
  }
  .carousel .carousel-inner .item .carousel-caption h1 {
    font-size: 31px;
    padding: 10px 10px;
  }

  #homeimages figure.large, #homeimages figure {
    height: 268px;
  }

  #product-menu {
    margin-top: 45px;
    background-color: #7b7879;
    text-transform: uppercase;
  }
  #product-menu .names .category-name h2 {
    color: white;
    font-family: "futura-pt";
  }
}
@media screen and (max-width: 767px) and (max-width: 768px) {
  #product-menu .names .category-name h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) and (max-width: 548px) {
  #product-menu .names .category-name h2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) and (max-width: 420px) {
  #product-menu .names .category-name h2 {
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .category-image {
    overflow: hidden;
    position: relative;
    box-shadow: inset 0 20px 18px -7px rgba(92, 92, 92, 0.6);
  }
}
@media (max-width: 821px) {
  .category-image {
    height: 200px !important;
  }
}
#product-menu {
  min-height: 0;
}
#product-menu.no-back .names {
  position: absolute;
  left: 20px !important;
  right: initial;
}
#product-menu li a {
  font-family: "Myriad Web";
}
#product-menu li.active {
  background-color: transparent !important;
}
#product-menu li.active a {
  color: #00a2b2 !important;
}
#product-menu .btn-navbar {
  background-color: #00a2b2 !important;
  color: #fff;
  border: 0;
}
@media screen and (max-width: 768px) {
  #product-menu .btn-navbar i {
    line-height: 32px;
  }
}

@media screen and (min-width: 767px) and (max-width: 782px) {
  #nav-container, header, #myCarousel {
    padding-left: 15px;
    padding-right: 15px;
  }
  #nav-container .container, header .container, #myCarousel .container {
    width: 730px;
  }
}

#product-menu .nav > li > a {
  font-weight: normal !important;
  text-transform: uppercase;
  font-size: 19px;
  padding: 3px 10px !important;
  font-family: "Myriad Web";
}

#product-menu.dealer-menu a {
  padding: 3px 10px !important;
}
#product-menu.dealer-menu a.active {
  background-color: #00a2b2;
  color: white !important;
}
#product-menu.dealer-menu .top-level.active a {
  padding: 8px 12px !important;
  background-color: #00a2b2;
  color: white !important;
  font-size: 25px;
}

#customiser-app .order-button {
  background-color: #00a2b2 !important;
}

#column-left .blog_item a, #column-left .blog_title a {
  color: #00a2b2 !important;
}

body[class^=blog-], body[class^=" blog-"] {
  font-family: "Myriad Web";
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background: #00a2b2 !important;
  border-color: #00a2b2 !important;
}

.form-control:focus {
  border-color: #00a2b2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(43, 162, 178);
}